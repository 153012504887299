import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Button, Typography, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import {  fetchTeams } from '../../../store/actions/teamActions';
import CounterWidget from '../../_shared-components/CounterWidget';
import Colors from 'src/styles/Colors';
import { API_URL } from 'src/config';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import useAxios from 'src/services/apiInterceptor';
import TeamAddModal from './Modals/TeamAddModal';
import CollaboratorAddModal from './CollaboratorsModals/CollaboratorAddModal'; // Import CollaboratorAddModal
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchTeamsStats } from 'src/store/actions/statsActions';
import { fetchTeamsCollaborators } from 'src/store/actions/teamCollaboratorsActions';


const Equipes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  /* const [teams, setTeams] = useState([]); */ // State to store teams
  const [open, setOpen] = useState(false);
  const [openCollaboratorModal, setOpenCollaboratorModal] = useState(false);
  const [teamData, setTeamData] = useState({ name: '', department: '', max_collaborators: 0 });
  const [errors, setErrors] = useState({});
  const [collaboratorData, setCollaboratorData] = useState({ first_name: '', last_name: '', email: '', password: '', team_id: '' });
  const [collaboratorErrors, setCollaboratorErrors] = useState({});
  const api = useAxios();
  const [loadingSubmit, setLoadingSubmit] = useState(false);



    const {
        totalTeams,
        totalCollaborators,
        collaboratorsUnderReview,
        teamsUnderReview,
        loading,
        error
    } = useSelector((state) => state.stats);

    useEffect(() => {
        dispatch(fetchTeamsStats());
    }, [dispatch]);

  const handleNavigate = (path) => {
    navigate(path);
  };
 

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTeamData({ name: '', department: '', max_collaborators: 0 });
    setErrors({});
  };

  const handleOpenCollaboratorModal = () => setOpenCollaboratorModal(true);
  const handleCloseCollaboratorModal = () => {
    setOpenCollaboratorModal(false);
    setCollaboratorData({ first_name: '', last_name: '', email: '', password: '', team_id: '', account_manager_id:''});
    setCollaboratorErrors({});
  };

  const handleSubmitTeam = async () => {
    setLoadingSubmit(true);
    setErrors({});
    try {
      await api.post(`${API_URL}/teams/manager`, { ...teamData, status: "active" });
      dispatch(fetchTeams());
      dispatch(fetchTeamsStats());
      handleClose();
    } catch (error) {
      const validationErrors = error.response?.data || {};
      setErrors(validationErrors);
      dispatch(showSnackbar('Échec de la création du cabinet comptable', 'error'));
    }
    setLoadingSubmit(false);
  };

  const handleSubmitCollaborator = async () => {
    setLoadingSubmit(true);
    setCollaboratorErrors({});
    try {

   
    
    let data = {
        first_name: collaboratorData.first_name,
        last_name: collaboratorData.last_name,
        company: collaboratorData.company,
        email: collaboratorData.email,
        password: collaboratorData.password,
        account_manager_id: collaboratorData.account_manager_id,

    }
    const response = await api.post(`${API_URL}/teams/collaborator`, data);
      dispatch(fetchTeamsCollaborators());
      dispatch(fetchTeams());
      dispatch(fetchTeamsStats());
      dispatch(showSnackbar('Expert comptable crée avec succés : '+ response?.data?.message, 'success'));

      handleCloseCollaboratorModal();
    } catch (error) {
      const validationErrors = error.response?.data || {};
      setCollaboratorErrors(validationErrors);
      dispatch(showSnackbar('Échec de la création de l\'Expert comptable : '+validationErrors, 'error'));
    } finally {
      setLoadingSubmit(false);
      
    }
  };

  return (
    <Container sx={{ pl: 0, pr: 0, mt: 1 }} style={{ padding: "0 !important" }}>
      <Box mb={2} variant="section">
        <Typography variant="h6" gutterBottom color={Colors.TITLE_1}>
          {location.pathname.includes("experts_comptables") ? "Experts comptables" : "Cabinets comptables"}
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ borderRadius: 4, height: '100%' }}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<AddIcon />}
                sx={{
                  textTransform: 'none',
                  height: '100%',
                  color: '#fff',
                  bgcolor: Colors.MEDIUM_GREEN,
                  '&:hover': {
                    bgcolor: Colors.DARK_GREEN,
                  },
                }}
                onClick={location.pathname.includes("experts_comptables") ? handleOpenCollaboratorModal : handleOpen}
              >
                <Typography variant="h6" component="div" gutterBottom>
                  {location.pathname.includes("experts_comptables") ? "Ajouter un Expert Comptable" : "Ajouter un Cabinet Comptable"}
                </Typography>
              </Button>
            </Card>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <CounterWidget
              title="Nombre de Cabinets Comptables"
              value={totalTeams || 0}
              additionalInfo={teamsUnderReview+ " sont en cours de diagnostic"}
              
              iconUrl="/chart-up.png"
              onClick={() => handleNavigate('list')}
              isActive={!location.pathname.includes("experts_comptables")}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <CounterWidget
              title="Total des Experts Comptables"
              value={totalCollaborators || 0}
              additionalInfo={collaboratorsUnderReview+ " sont en cours de diagnostic"}
              iconUrl="/chart-up.png"
              onClick={() => handleNavigate('experts_comptables')}
              isActive={location.pathname.includes("experts_comptables")}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Pass teams to Outlet */}
      <Outlet  />

      {/* Team Modal */}
      <TeamAddModal
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmitTeam}
        teamData={teamData}
        setTeamData={setTeamData}
        errors={errors}
        loading={loadingSubmit}
      />

      {/* Collaborator Modal */}
      <CollaboratorAddModal
        open={openCollaboratorModal}
        onClose={handleCloseCollaboratorModal}
        onSubmit={handleSubmitCollaborator}
        collaboratorData={collaboratorData}
        setCollaboratorData={setCollaboratorData}
        errors={collaboratorErrors}
        loading={loadingSubmit}
      />
    </Container>
  );
};

export default Equipes;

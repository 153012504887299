import React from 'react';
import {Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

const ChargesDeCompte = () => {



  return (
    <Container style={{flex: 1, display: "flex", flexDirection:"column"}}>

        <Outlet/>

     
    </Container>
  );
};

export default ChargesDeCompte;

 const menuItems = {
    admin: [
      { name: 'Dashboard', path: '/admin/dashboard', icon: 'dashboard.png' },
      { name: 'Cabinets', path: '/admin/cabinets', icon: 'equipes.png' },
      { name: 'Bilan', path: '/admin/bilan', icon: 'bilan.png' },
      { name: 'Questionnaire', path: '/admin/questionnaire', icon: 'questionnaire.png' },
    ],
    manager: [
      { name: 'Tableau de bord', path: '/manager/dashboard', icon: 'dashboard.png', key:'dashboard' },
      { name: 'Experts Comptables', path: '/manager/cabinets', icon: 'equipes.png', key: 'teams' },
      { name: 'Questionnaires', path: '/manager/questionnaire', icon: 'questionnaire.png', key:'surveys'},
      { name: 'Diagnostics & Résultats', path: '/manager/diagnostics', icon: 'resultats', key:'results' },
      { name: 'Chargés de compte', path: '/manager/charges_de_compte', icon: 'resultats', key:'accountManagers' },
   /*    { name: 'Bilan', path: '/manager/bilan', icon: 'bilan.png', key:'overview' }, */
    ],
    contributor: [
   
      { name: 'Diagnostic', path: '/contributor/questionnaire', icon: 'questionnaire.png', key:'surveys'},
      { name: 'Résultats', path: '/contributor/diagnostics/generation', icon: 'resultats', key:'results' },
    ],
  };
export default menuItems;
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, CircularProgress, MenuItem } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { FormTextField, FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';

const AddChargeModal = ({ open, onClose, onSubmit, chargeData, setChargeData, errors, loading }) => {
   

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChargeData({ ...chargeData, [name]: value });
    };

   

 

  

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
                    <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                    <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
                        Ajouter un Chargé de compte
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <FormTextField
                    autoFocus
                    margin="dense"
                    label="Prénom"
                    name="first_name"
                    fullWidth
                    variant="outlined"
                    value={chargeData.first_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                />
                <FormTextField
                    margin="dense"
                    label="Nom de famille"
                    name="last_name"
                    fullWidth
                    variant="outlined"
                    value={chargeData.last_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                />
                <FormTextField
                    margin="dense"
                    label="N° Tél"
                    name="phone"
                    fullWidth
                    variant="outlined"
                    value={chargeData.phone}
                    onChange={handleInputChange}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                />
                <FormTextField
                    margin="dense"
                    label="Email"
                    name="email"
                    fullWidth
                    variant="outlined"
                    value={chargeData.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />
            </DialogContent>

            <DialogActions sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
                    <FormButton onClick={onSubmit}>
                        {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Ajouter
                    </FormButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddChargeModal;

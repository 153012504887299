/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, CircularProgress, MenuItem } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormTextField, FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTeams } from 'src/store/actions/teamActions';
import { fetchAccountManagers } from 'src/store/actions/accountManagerActions';

const CollaboratorAddModal = ({ open, onClose, onSubmit, collaboratorData, setCollaboratorData, errors, loading }) => {
    const [passwordVisible, setPasswordVisible] = useState(true);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCollaboratorData({ ...collaboratorData, [name]: value });
    };

    const generatePassword = () => {
        const randomPassword = Math.random().toString(36).slice(-16);
        setCollaboratorData({ ...collaboratorData, password: randomPassword });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // eslint-disable-next-line no-unused-vars
    //const { allTeams = [], loading: teamsLoading, error: teamsError  } = useSelector(state => state.teams);
    const { accountManagers = [], loading: loadingAccountManagers, error: error  } = useSelector(state => state.accountManagers);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchAccountManagers());
    }, [dispatch]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
                    <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                    <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
                        Ajouter un Expert comptable
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <FormTextField
                    autoFocus
                    margin="dense"
                    label="Prénom"
                    name="first_name"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.first_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                />
                <FormTextField
                    margin="dense"
                    label="Nom de famille"
                    name="last_name"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.last_name}
                    onChange={handleInputChange}
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                />
                <FormTextField
                    margin="dense"
                    label="Cabinet"
                    name="company"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.company}
                    onChange={handleInputChange}
                    error={Boolean(errors.company)}
                    helperText={errors.company}
                />
                <FormTextField
                    margin="dense"
                    label="Email"
                    name="email"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.email}
                    onChange={handleInputChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />
                <FormTextField
                    margin="dense"
                    label="Mot de passe"
                    name="password"
                    fullWidth
                    variant="outlined"
                    type={passwordVisible ? 'text' : 'password'}
                    value={collaboratorData.password}
                    onChange={handleInputChange}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    InputProps={{
                        endAdornment: (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={generatePassword} sx={{ color: Colors.DARK_GREEN }}>
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        ),
                        startAdornment: (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={togglePasswordVisibility} sx={{ color: Colors.DARK_GREEN }}>
                                    {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </Box>
                        ),
                    }}
                />
                {/*  <FormTextField
                    margin="dense"
                    label="Cabinet Comptable"
                    name="company"
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.company}
                    onChange={handleInputChange}
                    error={Boolean(errors.company)}
                    helperText={errors.company}
                /> */}
                 <FormTextField
                    margin="dense"
                    label="Chargé de compte"
                    name="account_manager_id"
                    select
                    fullWidth
                    variant="outlined"
                    value={collaboratorData.account_manager_id}
                    onChange={handleInputChange}
                    error={Boolean(errors.account_manager_id)}
                    helperText={errors.account_manager_id}
                >
                    {accountManagers.map((ac) => (
                        <MenuItem key={ac.id} value={ac.id}>
                            {ac.first_name} {ac.last_name}
                        </MenuItem>
                    ))}
                </FormTextField> 
            </DialogContent>

            <DialogActions sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
                    <FormButton onClick={onSubmit}>
                        {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Ajouter
                    </FormButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CollaboratorAddModal;

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import teamReducer from './teamReducer';
import snackbarReducer from './snackbarReducer';
import teamsCollaboratorsReducer from './teamCollaboratorsReducer';
import surveysReducer from './surveysReducer';
import currentUserSurveysReducer from './currentUserSurveysReducer';
import profileReducer from './profileReducer';
import diagnosisReducer from './diagnosisReducer';
import statsReducer from './statsReducer';
import diagnosisResultReducer from './diagnosisResultReducer';
import accountManagerReducer from './accountManagersReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  teams: teamReducer,
  snackbar: snackbarReducer,
  collaborators: teamsCollaboratorsReducer,
  surveys: surveysReducer,
  currentUserSurveys: currentUserSurveysReducer,
  diagnosis: diagnosisReducer,
  profile: profileReducer,
  stats: statsReducer,
  diagnosisResult: diagnosisResultReducer,
  accountManagers: accountManagerReducer,
});

export default rootReducer;

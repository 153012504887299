import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, DialogActions, CircularProgress, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import useAxios from 'src/services/apiInterceptor';
import { API_URL } from 'src/config';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import { FormButton } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { FormTextField } from 'src/styles/CustomStyles';
import { CloseOutlined } from '@mui/icons-material';

const EditChargeModal = ({ open, onClose, onSubmit, chargeData, setChargeData, errors, loading }) => {

    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(event.target)
        console.log(chargeData)
        setChargeData({ ...chargeData, [name]: value });
    };


    return (
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
                <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
                    Mettre à jour un Chargé de compte
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseOutlined />
                </IconButton>
            </Box>
        </DialogTitle>

        <DialogContent>
            <FormTextField
                autoFocus
                margin="dense"
                label="Prénom"
                name="first_name"
                fullWidth
                variant="outlined"
                value={chargeData.first_name}
                onChange={handleInputChange}
                error={Boolean(errors.first_name)}
                helperText={errors.first_name}
            />
            <FormTextField
                margin="dense"
                label="Nom de famille"
                name="last_name"
                fullWidth
                variant="outlined"
                value={chargeData.last_name}
                onChange={handleInputChange}
                error={Boolean(errors.last_name)}
                helperText={errors.last_name}
            />
            <FormTextField
                margin="dense"
                label="N° Tél"
                name="phone"
                fullWidth
                variant="outlined"
                value={chargeData.phone}
                onChange={handleInputChange}
                error={Boolean(errors.phone)}
                helperText={errors.phone}
            />
            <FormTextField
                margin="dense"
                label="Email"
                name="email"
                fullWidth
                variant="outlined"
                value={chargeData.email}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
            />
        </DialogContent>

        <DialogActions sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box display="flex" alignItems="center" gap={1}>
                <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
                <FormButton onClick={onSubmit}>
                    {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Mettre à jour
                </FormButton>
            </Box>
        </DialogActions>
    </Dialog>
    );
};

export default EditChargeModal;

import axios from 'axios';
import { API_URL } from 'src/config';

export const FETCH_ACCOUNT_MANAGERS_REQUEST = 'FETCH_ACCOUNT_MANAGERS_REQUEST';
export const FETCH_ACCOUNT_MANAGERS_SUCCESS = 'FETCH_ACCOUNT_MANAGERS_SUCCESS';
export const FETCH_ACCOUNT_MANAGERS_FAILURE = 'FETCH_ACCOUNT_MANAGERS_FAILURE';


export const FETCH_ALL_ACCOUNT_MANAGERS_REQUEST = 'FETCH_ALL_ACCOUNT_MANAGERS_REQUEST';
export const FETCH_ALL_ACCOUNT_MANAGERS_SUCCESS = 'FETCH_ALL_ACCOUNT_MANAGERS_SUCCESS';
export const FETCH_ALL_ACCOUNT_MANAGERS_FAILURE = 'FETCH_ALL_ACCOUNT_MANAGERS_FAILURE';


export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';



export const fetchAccountManagers = (page = 1) => async (dispatch, getState) => {
  const { filters, sortBy, sortOrder, perPage } = getState().accountManagers;
  const token = getState().auth.token;

  dispatch({ type: FETCH_ACCOUNT_MANAGERS_REQUEST });
  
  try {
    const response = await axios.get(`${API_URL}/account_managers/paginate`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: {
        page,
        per_page: perPage,
        filters: new URLSearchParams(filters).toString(),
        sort_by: sortBy,
        sort_order: sortOrder
      }
    });
    
    dispatch({
      type: FETCH_ACCOUNT_MANAGERS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_ACCOUNT_MANAGERS_FAILURE,
      payload: error.message
    });
  }
};

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters
});

export const setSortBy = (sortBy) => ({
  type: SET_SORT_BY,
  payload: sortBy
});

export const setSortOrder = (sortOrder) => ({
  type: SET_SORT_ORDER,
  payload: sortOrder
});

export const setPerPage = (perPage) => ({
  type: SET_PER_PAGE,
  payload: perPage
});

export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage
});

// Fetch all teams action
export const fetchAllAccountManagers = () => async (dispatch, getState) => {
    const token = getState().auth.token;
  
    dispatch({ type: FETCH_ALL_ACCOUNT_MANAGERS_REQUEST });
  
    try {
      const response = await axios.get(`${API_URL}/account_managers/all`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      console.log(response.data)
      dispatch({
        type: FETCH_ALL_ACCOUNT_MANAGERS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_ACCOUNT_MANAGERS_FAILURE,
        payload: error.message
      });
    }
  };



import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow,
  InputLabel, IconButton, Tooltip, Typography,
  Grid,
  Button,
  Card,
  Select,
  MenuItem
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAccountManagers, setCurrentPage, setFilters, setPerPage, setSortBy, setSortOrder
} from '../../../store/actions/accountManagerActions'; // Adjusted action import
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { FiltersTextField, FilterButton } from 'src/styles/CustomStyles';
import { API_URL } from 'src/config';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import useAxios from 'src/services/apiInterceptor';
import EditChargeModal from './Modals/EditChargeModal';
import DeleteChargeModal from './Modals/DeleteChargeModal';
import { CustomTablePagination } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { AddOutlined, DeleteOutlined, EditOutlined, FileDownloadOutlined, RefreshOutlined } from '@mui/icons-material';
import CounterWidget from 'src/components/_shared-components/CounterWidget';
import { useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from "xlsx";
import { FilterListFormControl } from 'src/styles/CustomStyles';
import AddChargeModal from './Modals/AddChargeModal';

const exportToExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Teams Data');
    XLSX.writeFile(wb, 'teams_data.xlsx');
  };

const ChargesDeCompteList = () => {
    const dispatch = useDispatch();
    const axios = useAxios();
    const navigate = useNavigate();
    const location = useLocation();
    const {
      accountManagers,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.accountManagers); // Adjusted state slice

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [chargeData, setChargeData] = useState({ first_name: '', last_name: '', email: '', password: '', team_id: '' });
    
    const [chargeErrors, setChargeErrors] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);



    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        dispatch(setPerPage(parseInt(event.target.value, 10)));
        setPage(0);
    };
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCharge, setSelectedCharge] = useState(null);
    
    useEffect(() => {
      dispatch(fetchAccountManagers(page + 1));
    }, [dispatch, filters, sortBy, sortOrder, page]);

    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };

    const handleClearFilters = () => {
        dispatch(setFilters({}));
        dispatch(setSortBy('id'));
        dispatch(setSortOrder('desc'));
    };


    const handleOpen = () => setAddModalOpen(true);
    
    const handleClose = () => {
      setAddModalOpen(false);
      setChargeData({ first_name: '', last_name: '', email: '', phone: '' });
      setChargeErrors({});
    };
  

  
    const handleSubmit= async () => {
      setLoadingSubmit(true);
      setChargeErrors({});
      try {
  
     
      
      let data = {
          first_name: chargeData.first_name,
          last_name: chargeData.last_name,
          phone: chargeData.phone,
          email: chargeData.email,
  
      }
      const response = await axios.post(`${API_URL}/account_managers`, data);
        dispatch(fetchAccountManagers());
        dispatch(showSnackbar('Chargé de compte crée avec succés : '+ response?.data?.message, 'success'));
  
        handleClose();
      } catch (error) {
        const validationErrors = error.response?.data || {};
        setChargeErrors(validationErrors);
        dispatch(showSnackbar('Échec de la création du chargé de compte : '+validationErrors, 'error'));
      } finally {
        setLoadingSubmit(false);
        
      }
    };

    const [chargeEditErrors, setChargeEditErrors] = useState({});
    const [loadingEditSubmit, setLoadingEditSubmit] = useState(false);

    const handleEditClick = (charge) => {
        setSelectedCharge(charge);
        setChargeData({
          first_name: charge.first_name,
          last_name: charge.last_name,
          phone: charge.phone,
          email: charge.email,
          id: charge.id, // Add id so you can reference it in the PUT request
        });
        setEditModalOpen(true);
    };

    const handleEditModalClose = () => {
      setEditModalOpen(false);
      setSelectedCharge(null);
      setChargeErrors({});
    };

    const handleEditSubmit= async () => {
        setLoadingEditSubmit(true);
        setChargeErrors({});
        try {
    
            let data = {
                first_name: selectedCharge.first_name,
                last_name: selectedCharge.last_name,
                phone: selectedCharge.phone,
                email: selectedCharge.email,
        
            }
            const response = await axios.put(`${API_URL}/account_managers/${chargeData.id}`, data);
            dispatch(fetchAccountManagers());
            dispatch(showSnackbar('Chargé de compte mis à jour avec succés : '+ response?.data?.message, 'success'));
        
            handleEditModalClose();
        } catch (error) {
          const validationErrors = error.response?.data || {};
          setChargeEditErrors(validationErrors);
          dispatch(showSnackbar('Échec de la mise à jour du chargé de compte : '+validationErrors, 'error'));
        } finally {
          setLoadingEditSubmit(false);
        }
      };

    const handleDeleteClick = (charge) => {
      setSelectedCharge(charge);
      setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
      setDeleteModalOpen(false);
      setSelectedCharge(null);
    };

    const handleDeleteSubmit = async () => {
      try {
        await axios.delete(`${API_URL}/account_managers/${selectedCharge.id}`);
        dispatch(showSnackbar('Chargé de compte supprimé avec succés', 'success'));
        dispatch(fetchAccountManagers());
        handleDeleteModalClose();
      } catch (error) {
        console.error("Echec de suppression du chargé de compte :", error.message);
        dispatch(showSnackbar('Echec de suppression du chargé de compte', error.message, 'error'));
      }
    };

    return (
      <Box>
        <Box mb={2} style={{ display: "flex", flexWrap:"wrap", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" gutterBottom color="textPrimary">
            Liste des Chargés de Compte
          </Typography>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ borderRadius: 4, height: '100%' }}>
                <Button
                  fullWidth
                  variant="contained"
                  endIcon={<AddOutlined />}
                  sx={{
                    textTransform: 'none',
                    height: '100%',
                    color: '#fff',
                    bgcolor: Colors.MEDIUM_GREEN,
                    '&:hover': {
                      bgcolor: Colors.DARK_GREEN,
                    },
                  }}
                  onClick={handleOpen}
                >
                  <Typography variant="h6" component="div" gutterBottom>
                    Ajouter un Chargé de Compte
                  </Typography>
                </Button>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CounterWidget
                title="Total des Chargés de compte"
                value={0}
                additionalInfo={" "}
                iconUrl="/chart-up.png"
                onClick={null}
                isActive={location.pathname.includes("charges_de_compte")}
              />
            </Grid>
          </Grid>
        </Box>
        
        {/* Filters Section */}
        <Box display="flex" flexWrap="wrap" gap={2}>
           {/* First Name Filter */}
           <FiltersTextField
                    label="Prénom"
                    variant="outlined"
                    size="small"
                    value={filters.first_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, first_name: e.target.value }))}
            />

            {/* Last Name Filter */}
            <FiltersTextField
                label="Nom de famille"
                variant="outlined"
                size="small"
                value={filters.last_name || ''}
                onChange={(e) => dispatch(setFilters({ ...filters, last_name: e.target.value }))}
            />
            <FilterListFormControl size="small">
              <InputLabel>Tri Par</InputLabel>
              <Select
                value={sortBy}
                label="Tri par"
                onChange={(e) => dispatch(setSortBy(e.target.value))}
              >
                <MenuItem value="id">ID</MenuItem>
                <MenuItem value="first_name">Prénom</MenuItem>
                <MenuItem value="last_name">Nom</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">N° Tél</MenuItem>
                <MenuItem value="created_at">Créé Le</MenuItem>
              </Select>
            </FilterListFormControl>
            <FilterListFormControl size="small">
              <InputLabel>Ordre de Tri</InputLabel>
              <Select
                label="Ordre de Tri"

                value={sortOrder}
                onChange={(e) => dispatch(setSortOrder(e.target.value))}
              >
                <MenuItem value="asc">Ascendant</MenuItem>
                <MenuItem value="desc">Descendant</MenuItem>
              </Select>
            </FilterListFormControl>
            <Tooltip title="Rafraichir et vider les filtres">
                <IconButton onClick={handleClearFilters} color="primary">
                    <RefreshOutlined />
                </IconButton>
            </Tooltip>
            
            {/* Export Button */}
            <Tooltip title="Exporter sous format Excel">
                <FilterButton
                variant="contained"
                color="primary"
                startIcon={<FileDownloadOutlined />}
                onClick={() => exportToExcel(accountManagers)}
                >
                Exporter
                </FilterButton>
            </Tooltip>
        </Box>

        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 2 }}>
          <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell className="center-align">ID</TableCell>
                <TableCell className="center-align">Nom</TableCell>
                <TableCell className="center-align">Email</TableCell>
                <TableCell className="center-align">Date de Création</TableCell>
                <TableCell className="center-align">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={5} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={5} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                accountManagers.map((charge) => (
                  <TableRow key={charge.id}>
                    <TableCell className="center-align">{charge.id}</TableCell>
                    <TableCell className="center-align">{`${charge.first_name} ${charge.last_name}`}</TableCell>
                    <TableCell className="center-align">{charge.email}</TableCell>
                    <TableCell className="center-align">{new Date(charge.created_at).toLocaleString('fr-FR')}</TableCell>
                    <TableCell>
                      <Tooltip title="Editer Charge">
                        <IconButton onClick={() => handleEditClick(charge)}>
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Supprimer Charge">
                        <IconButton sx={{ color: "#ff0000" }} onClick={() => handleDeleteClick(charge)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Pagination */}
        <CustomTablePagination
          rowsPerPageOptions={[10, 25,50,100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Afficher"
          nextIconButtonProps={{
            'aria-label': 'Page suivante',
          }}
          backIconButtonProps={{
            'aria-label': 'Page précédente',
          }}
        />
        
        {/* Collaborator Modal */}
        <AddChargeModal
            open={addModalOpen}
            onClose={handleClose}
            onSubmit={handleSubmit}
            chargeData={chargeData}
            setChargeData={setChargeData}
            errors={chargeErrors}
            loading={loadingSubmit}
        />
        {/* Edit Charge Modal */}
        {selectedCharge && (
        <EditChargeModal
            open={editModalOpen}
            onClose={handleEditModalClose}
            onSubmit={handleEditSubmit}
            chargeData={selectedCharge}
            setChargeData={setSelectedCharge}
            errors={chargeEditErrors}
            loading={loadingEditSubmit}
        />
      )}
       {/* Delete Chargé Modal */}
       {selectedCharge && (
        <DeleteChargeModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          onSubmit={handleDeleteSubmit}
          chargeData={selectedCharge}
          loading={loadingSubmit}
        />
      )}
      </Box>
    );
};

export default ChargesDeCompteList;
